@font-face {
  font-family: "SF Pro Display";
  src: url("../public/font/SFPRODISPLAYREGULAR.OTF");
  font-weight: lighter;
  font-style: lighter;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/font/SFPRODISPLAYBOLD.OTF");
  font-weight: Bold;
  font-style: Bold;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/font/SFPRODISPLAYMEDIUM.OTF");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "SF Pro Display";
}

.sidebar-container {
  position: fixed;
  z-index: 100;
  width: 18%;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 100vh;
  padding: 20px 0px 20px 0px;
  background-color: #231f20;
}
.home {
  width: 82%;
  margin-left: 18%;
}
.login-1 {
  width: 100%;
  height: 100vh;
  background-color: #231f20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo {
  width: 370px;
}

.sidebar {
  color: white;
}

.side-icon-combine {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  border-left: 8px solid #231f20;
  padding: 6px 30px;
  cursor: pointer;
  gap: 30px;
}

.side-icon-combine.active {
  background-color: #312e2e;
  border-left: 8px solid white;
}

.side-total {
  width: 100%;
}

.side-bar-contain {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.icon-side {
  display: flex;
  justify-content: center;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 500;
}

.side-content,
.link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
}

.side-content-1 {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.dropdown-icon {
  margin-top: 4px;
  cursor: pointer;
  display: flex;
  justify-content: end;
  width: 30%;
}

.home-dropdown-combine {
  display: flex;
  margin-left: 41px;
  justify-content: center;
  align-items: center;
}

.home-dropdown-content {
  display: flex;
  width: 60%;
  justify-content: start;
  flex-direction: column;
  align-items: start;
}
.side-drop {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 150px;
  margin: auto;
  margin-bottom: 50px;
}

.header-combine {
  display: flex;
  justify-content: end;
  width: 80.5%;
  gap: 20px;
}

.login-header {
  display: flex;
  cursor: pointer;
  gap: 10px;
}

.header-container {
  box-shadow: 3px 4px 5px lightgray;
  padding: 10px;
  background-color: white;
  width: 100%;
  position: fixed;
  z-index: 99;
}

.profile-img {
  width: 40px;
  height: 40px;
}

.profile-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.notify {
  background-color: #ededed;
  color: #000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notify-img {
  width: 150px;
  height: 120px;
  padding-top: 10px;
}

.notify-content {
  text-align: center;
  margin-top: 50px;
}

.notify-content > h5 {
  font-size: 20px;
  font-weight: 700;
}
.notify-content > p {
  font-size: 14px;
  font-weight: 400;
  padding-top: 20px;
  color: #4d4e50;
}

.section-main {
  padding: 10px 20px;
}

.top-gap {
  padding-top: 95px;
  padding-bottom: 50px;
}

.head-txt {
  font-size: 22px;
  font-weight: 700;
  font-weight: bold;
}

.offer-img {
  width: 107px;
}

.head-txt-s {
  font-size: 22px;
  font-weight: 400;
}

.box {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  /* width: 220px; */
  padding: 10px;
}

.row {
  --bs-gutter-x: none !important;
}

.order-div {
  display: flex;
}

.order-row {
  /* padding-top: 20px; */
  justify-content: space-between;
}

.box-ins-row {
  /* border:2px solid blue; */
  justify-content: space-between;
}

.box-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 30px;
  background-color: #d1d1d1;
}

.txt-box {
  margin-bottom: 0px;
  font-size: 10px;
  color: #039800;
  font-weight: 400;
}
.box-head {
  font-size: 10px;
  font-weight: 500;
  color: #444444;
  margin-bottom: 0px;
}
.box-no {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
}

.css-g2aoha-MuiTableCell-root,
.css-1t7lzbz-MuiTableCell-root {
  width: 150px;
  text-align: center !important;
  font-size: 17px;
  font-weight: 600 !important;
}
.css-1tti7u1-MuiTableCell-root,
.css-10ukr6t-MuiTableCell-root {
  text-align: center !important;
  font-size: 16px;
  padding: 12px !important;
  font-weight: 500 !important;
}

.labels-chart-order {
  font-size: 10px;
}
.chart-label-option-2 {
  width: 70%;
  text-align: start;
}
.css-v73c9r-MuiPaper-root-MuiTableContainer-root {
  border: 1px solid #d1d1d1 !important;
}

.table-txt-s {
  font-size: 12px !important;
  color: #6c6c6c;
  font-weight: 400 !important;
}

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border: none !important;
  /* padding: 5px !important; */
  padding: 10px 0px !important;
}

.combine-user {
  display: flex;
  gap: 30px;
}

.head-h {
  width: 70px !important;
}

.combine-sales {
  display: flex;
  justify-content: space-between;
}

.card {
  border: 1px solid #d1d1d1 !important;
  /* width: 700px; */
  height: 300px;
  padding: 10px !important;
}

.charts {
  width: 650px;
  height: 280px;
}
.charts-1 {
  width: 100%;
  height: 280px;
}
.css-b9rdri-MuiGauge-referenceArc {
  fill: #231f20 !important;
}

.gauages {
  width: 200px !important;
  height: 100px !important;
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.gauage-txt {
  font-size: 10px;
  font-weight: 500;
  color: #444444;
}

.gauage-box {
  height: 192px;
  padding: 11px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-div {
  padding-right: 20px;
}

@media screen and (min-width: 320px) and (max-width: 578px) {
  .sidebar-container {
    width: 18% !important;
    padding: 10px 0px !important;
  }
  .side-bar-contain {
    gap: 20px;
  }
  .logo {
    width: 50px;
    margin: start !important;
  }
  .gaping-side {
    margin-top: 0px !important;
    gap: 20px !important;
  }
  .side-content,
  .dropdown-icon,
  .side-content-1 {
    display: none;
  }
  .side-icon-combine {
    margin: 0% 15%;

    justify-content: center !important;
  }
  .home-icon,
  .log-icon {
    margin-left: -18px;
  }

  .header-combine {
    width: 100% !important;
  }
  .header-container {
    width: 84%;
  }
  .gauage-box {
    width: 100%;
  }
  .file {
    font-size: 10px !important;
  }
  .combine-user {
    flex-direction: column;
    gap: 5px !important;
  }
  .head-txt,
  .head-txt-s {
    font-size: 13px;
  }
  .box {
    width: 100%;
  }
  .section-main {
    padding: 10px !important;
  }
  .top-gap {
    padding-top: 70px !important;
  }
  .row {
    gap: 20px;
  }
  .card,
  .charts {
    width: 100% !important;
  }
}

.file {
  color: #a7a7a7;
  text-decoration: none;
  font-size: 16px;
  width: 120px;
  margin: 6px 0px;
  font-weight: lighter;
}
.file-active{
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  width: 120px;
  margin: 6px 0px;
  font-weight: lighter;
}

.file:active {
  color: white;
}

.gaping-side {
  display: flex;
  width: 100%;
  gap: 140px;
  flex-direction: column;
}

.dealofselling {
  display: flex;
  width: 83%;
  justify-content: space-between;
}

.demo-img {
  width: 52px;
}

.select-brand {
  width: 248px;
  height: 35px;
  background-color: #ededed !important;
  cursor: pointer;
  border-radius: 5px !important;
  border: 1px solid #d1d1d1 !important;
}

.select-brand-deal{
  width: 455px;
  height: 35px;
  background-color: #ededed !important;
  cursor: pointer;
  border-radius: 5px !important;
  border: 1px solid #d1d1d1 !important;
}

.select-brand-b {
  width: 340px;
  height: 35px;
  background-color: #ededed !important;
  cursor: pointer;
  border-radius: 5px !important;
  border: 1px solid #d1d1d1 !important;
}
.btn-m {
  margin-top: 30px;
}
.table-heads {
  font-size: 16px;
  width: 150px;
  font-weight: 700 !important;
}

.select-brand-bought {
  border: none;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
:where(.css-dev-only-do-not-override-5wsri9).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid #d1d1d1 !important;
  background-color: #ededed !important ;
}

.select-combine {
  display: flex;
  gap: 20px;
}

.btn-1 {
  background-color: #000 !important;
  color: white !important;
  border: 1px solid black !important;
  height: 36px !important;
  width: 120px !important;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-drawer .ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn-2 {
  background-color: white !important;
  color: #000 !important;
  border: 1px solid black !important;
  height: 36px !important;
  width: 120px !important;
}
.btn-3{
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid black !important;
  height: 35px !important;
  width: 35px !important;
  padding: 0px !important;
}
.nav-link{
  color: #231F20 !important;
}
.custom-tabs .nav-link.active {
  background-color: #ededed !important; 
  color: #000 !important; 
  font-weight: bold;
}
.custom-tabs .nav-link {
  color: #555;
  transition: background-color 0.3s ease;
}

.custom-tabs .nav-link:hover {
  background-color: #f0f0f0;
}



.down {
  color: #000 !important;
  font-size: 16px;
}

.brand-img {
  width: 126px;
}

.brand-table-data {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #000 !important;
}

.edit {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.brand-label {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
}

.brand-label-s {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
}
.per {
  font-size: 10px;
}

@media screen and (min-width: 330px) and (max-width: 578px) {
  .select-combine {
    flex-direction: column;
  }
  .select-brand {
    width: 100% !important;
  }
  .brand-label {
    font-size: 12px;
  }
  .brand-input,
  :where(.css-dev-only-do-not-override-5wsri9).ant-input-outlined {
    width: 100% !important;
  }
  .newbrand-checkbox {
    width: 100% !important;
  }
  .upload-div {
    width: 100% !important;
  }
}

.head-txt-modal {
  font-size: 18px;
  font-weight: 600;
}

.brand-txt {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.addbrand-label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.brand-input,
:where(.css-dev-only-do-not-override-5wsri9).ant-input-outlined {
  /* width: 455px; */
  padding: 6px 10px;
  margin-bottom: 10px;
  background-color: #ededed !important;
  border: 1px solid #d9d9d9 !important;
}

.brand-input::placeholder {
  color: #444444;
  font-size: 16px;
  font-weight: 500;
}
.brand-input-1 {
  width: 250px;
}

.brand-input-text {
  width: 100%;

  padding: 18px 0px;
}
.input-combine {
  width: 90%;
}

.input-combine-div {
  display: flex;
  height: 25px;

  justify-content: space-between;
}

.modal-header {
  background-image: url("../public/assets/header-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  height: 400px;
  flex-direction: column;
}

.label-3 {
  font-size: 13px;
  font-weight: 500;
}
.brand-select {
  width: 455px;
  height: 35px;
  margin-bottom: 10px;
}

.categories-label {
  font-size: 14px;
  font-weight: 400;
}

.css-1y5vrnu-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1y5vrnu-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #000 !important;
}

.newbrand-checkbox {
  width: 455px;
}

.label-2 {
  font-size: 16px;
  font-weight: 400;
}

.upload-div {
  width: 300px;
  background-color: white;
  padding: 15px 25px;
  border: 2px dotted #d9d9d9 !important;
  border-radius: 5px;
}

.upload {
  font-size: 25px;
  font-weight: 700;
}

.addnew-btn-combine {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.addnew-btn-combine-b {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 20px;
}
.table-scroll {
  overflow-x: scroll !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.table-scroll::-webkit-scrollbar {
  display: none !important;
}
.css-v73c9r-MuiPaper-root-MuiTableContainer-root {
  height: 100% !important;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #231f20 !important;
}
.css-19wsa2m-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #231f20 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0px 15px !important;
  padding: 0px 4px !important;
  background-color: #6c6c6c6b !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height:30px !important;
  border-radius: 4px !important;


  /* padding-bottom: 5px !important; */
}
.css-heg063-MuiTabs-flexContainer{
  justify-content: start !important;
  align-items: center !important;
}
.css-19wsa2m-MuiButtonBase-root-MuiTab-root {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 0px 4px !important;
  margin: 0px 15px !important;
  background-color: #EDEDED !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height:30px !important;
  border-radius: 4px !important;
}
.css-1sj2jcg-MuiTabs-indicator{
  display: none !important;
}
.css-1sj2jcg-MuiTabs-indicator {
  background-color: #231f20 !important;
}
.css-19kzrtu {
  padding: 10px 0 0 0 !important;
}
.select-brand {
  width: 248px;
  background-color: #ededed !important;
  border-radius: 5px !important;
  border: 1px solid #d1d1d1 !important;
}

.select-brand-o {
  width: 115px;
  background-color: #ededed !important;
  border-radius: 5px !important;
  border: 1px solid #d1d1d1 !important;
}

.offers-inputs {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: end;
}
.tableborder {
  border: 1px solid #d1d1d1 !important;
  border-radius: 5px !important;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: #fff !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.col-box-2 {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 20px 5px 15px 5px;
}
.hot-box {
  width: 335px;
  height: 307px;
  padding-right: 20px;
  padding-top: 50px;
}

@media only screen and (min-width: 1300px) and (max-width: 1550px) {
  .hot-box {
    width: 410px !important;
    padding-right: 0px !important;
  }
  .hot-box-f {
    width:100%;
    gap:20px;
  }
  .card{
    margin-top: 30px;
  }
}
.hot-box-f {
  display: flex;
  width: 100%;
  padding-bottom: 60px;
}
.icons {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  color: #000;
}

.icons.active {
  background-color: #000;
  color: white;
}
.icons > i {
  font-size: 17px;
  font-weight: 700;
  padding-top: 7px;
}

.countdown-segment {
  background-color: #ededed;
  width: 37px;
  height: 47px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-segment p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px !important;
}
.countdown-segment span {
  font-size: 8px;
  font-weight: 400;
}
.countdown-container {
  width: 130px;
}
.countdown-container-1 {
  width: 140px;
}
.offer-deal {
  position: absolute;
  top: 0%;
  width: 50px;
  right: 0px;
}
.deal-title {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}

.deal-rating {
  font-size: 12px;
}

.deal-para {
  font-size: 13px;
  font-weight: 600;
}

.deal-para > span {
  color: gray;
  font-size: 11px !important;
}

.buy-now {
  background-color: #000 !important;
  color: white !important;
  border: 1px solid #000 !important;
  font-size: 10px !important;
}

.cart-button {
  background-color: #fff !important;
  color: #000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000 !important;
  padding: 5px 10px !important;
}

.graph-analytics-box {
  border: 1px solid #d1d1d1;
  height: 400px;
  padding: 20px;
  border-radius: 5px;
}
.charts-2 {
  height: 370px;
}
.user-txt {
  font-size: 14px;
  font-weight: 400;
  color: #231f20;
  margin-bottom: 0px;
  padding-top: 0px !important;
}

.user-txt-1 {
  font-size: 12px !important;
  font-weight: 500;
}

.css-19wsa2m-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
}

.product-btn {
  padding-top: 20px;
  display: flex;
  justify-content: end;
}

/* App.css */
.custom-table {
  border: 1px solid #d1d1d1; /* Change this to your desired border color */
  border-radius: 8px; /* Optional: Rounded corners */
  overflow: hidden; /* Ensures child elements don't overflow */
}

:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none !important;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper tfoot > tr > td {
  width: 100px !important;
  height: 60px;
  font-size: 13px;
  font-weight: 400 !important;
}

.table-txt-s {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #6c6c6c;
}

.table-txt-m {
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #6c6c6c;
}

.icon-table {
  font-size: 16px !important;
  margin-top: 15px;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background-color: #fff;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  font-size: 16px;
  font-weight: 700 !important;
  width: 250px !important;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  padding-top: 5px;
  padding-bottom: 5px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-5wsri9).ant-table-wrapper tfoot > tr > td {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
}

.btn-down {
  background-color: #000 !important;
  color: White !important;
  border: 1px solid black !important;
  padding-left: 37px;
  padding-right: 37px;
}

.downs {
  color: white;
  margin-left: 5px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-picker
  .ant-picker-input
  > input:placeholder-shown {
  display: none;
}

.select-brand-1 {
  width: 248px;
  height: 35px;
  border: 1px solid #d1d1d1 !important;
  cursor: pointer;
  background-color: #ededed;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-picker-outlined {
  background-color: #ededed !important;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-picker .ant-picker-input {
  justify-content: end;
  font-size: 14px;
}

.upload-circle {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

:where(
    .css-dev-only-do-not-override-5wsri9
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
:where(
    .css-dev-only-do-not-override-5wsri9
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-settings {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 100px;
  gap: 20px;
}

.setting-data {
  width: 200px;
  padding: 10px !important;
}

.data-setting {
  padding: 25px 0px !important;
}

.txt-1 {
  font-size: 16px;
  font-weight: 700;
}

.add-box {
  border: 2px dotted #d1d1d1;
  border-radius: 5px;
}
.txt-2 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}
.checkbox-admin {
  margin-top: 5px;
}

.checkbox-admin > label {
  font-size: 14px;
  font-weight: 500;
}

.admin-checkbox {
  width: 450px;
  display: flex;
  flex-wrap: wrap;
}

.product-input {
  width: 90px; /* Adjust width */
  height: 40px; /* Adjust height */
}

.product-input-color {
  width: 80%;
  padding: 0.3125rem; /* Equal to 5px */
  border: 1px solid #d1d1d1;
  border-radius: 0.3125rem; /* Equal to 5px */
  background-color: #ededed;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  box-sizing: border-box;
}
.product-input-color:hover {
  background-color: #f5f5f5;
  border-color: #bdbdbd;
}
.product-input-color:focus {
  outline: none;
  background-color: #ffffff;
  border-color: #8c8c8c;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.product-box {
  display: flex;
  /* gap: 30px;
  justify-content: center; */
  justify-content: space-between;
  align-items: center;
}

.product-box-1 {
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.addbrand-label > span {
  font-size: 16px;
  font-weight: 400;
}

.inputs-3 {
  width: 150px;
}

.product-box-2 {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  width: 100%;
}
.product-box-3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.inputs-2 {
  width: 150px;
}
.product-input::placeholder {
  color: #444444;
}

.product-input-1::placeholder {
  color: #444444;
}
.product-contain-box {
  box-sizing:content-box;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  padding: 40px;
  /* width: 100%; */
}

.top-clear {
  position: absolute;
  top: 54%;
  right: 3.4%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-clear-1 {
  position: absolute;
  bottom: -56%;
  left: 60.5%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-clear-2 {
  position: absolute;
  top: 98.5%;
  right: 3.8%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-clear-3 {
  position: absolute;
  bottom: -69.7%;
  left: 56%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-clear-4 {
  position: absolute;
  bottom: -114%;
  left: 55.5%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-clear-5 {
  position: absolute;
  bottom: -158%;
  left: 55.5%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-clear-lp-1 {
  position: absolute;
  left: 56%;
  bottom: -78%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-clear-lp-2 {
  position: absolute;
  left: 55.5%;
  bottom: -123%;
  background-color: #000;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-btn-box {
  background-color: white !important;
  color: #000 !important;
  padding: 1% 40%;
  margin-top: 10px;
  margin-left: 8%;
  font-size: 20px;
  font-weight: 600;
  border: 2px dotted #d1d1d1 !important;
}

.product-input-1 {
  width: 80%;
}

.abt-icon {
  color: #000;
  font-size: 10px;
  font-weight: 600;
}

.col-divs {
  padding: 0px 0px 0px 0px;
}

.col-divs-1 {
  padding: 0px 0px 0px 20px;
}

.login-form {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-txt-1 {
  font-size: 16px;
  text-align: center;
  padding-top: 15px;
  color: #231f20;
  font-weight: 500;
}

.login-txt-2 {
  font-size: 14px;
  text-align: center;
  color: #231f20;
  font-weight: 400;
}

.login-div > label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.otp-input {
  width: 43px;
  border-radius: 3px !important;
}

.login-div {
  width: 100%;
}

.otp-combine {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  /* padding: 0px 30px; */
}

:where(.css-dev-only-do-not-override-5wsri9).ant-drawer .ant-drawer-header {
  display: none;
}

.circle-container {
  width: 200px; /* Adjust as needed */
  height: 200px !important; /* Adjust as needed */
  border-radius: 50%; /* Makes it circular */
  overflow: hidden; /* Ensures waves don't overflow */
  position: relative;
  border: 1px solid #000;
  position: relative; /* Allows absolute positioning of the wave */
  height: 100px; /* Height of the container */
  overflow: hidden; /* Allows absolute positioning of children */
}

.wavify {
  position: absolute;
  bottom: -10px; /* Move it down */
  left: 0; /* Center it horizontally */
  right: 0; /* Center it horizontally */
}

.content {
  position: absolute; /* Position the content absolutely */
  top: 40%; /* Adjust this value to position content vertically */
  left: 50%; /* Center the content horizontally */
  transform: translate(-50%, -50%); /* Center the content */
  text-align: center; /* Center text inside the content */
  color: #000; /* Change text color to be visible against the wave */ /* Semi-transparent background for contrast */
  padding: 10px; /* Add some padding */
  border-radius: 8px; /* Rounded corners */
}
.content-1 {
  font-size: 12px;
  font-weight: 400;
  color: #00000073;
  margin-bottom: 0px;
}

.content-2 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
}

.img-drawer {
  width: 52px;
}

.circle-container > .waves {
  position: absolute; /* Position the SVG absolutely */
  bottom: 0; /* Align the wave to the bottom */
  width: 100%; /* Make the wave fill the width of the container */
  height: auto; /* Keep the height auto for the wave */
}

.mrp-box {
  display: flex;
}
.discount-box {
  display: flex;
}

.suffix {
  z-index: 9;
  margin-left: -20px;
}

.drag {
  width: 455px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-upload-wrapper
  .ant-upload-drag {
  width: 455px;
}

.offer-input-2 {
  width: 190px;
}

.offer-input-1 {
  width: 245px;
}
.links {
  text-decoration: none;
  color: #000 !important;
}

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  font-family: "SF Pro Display" !important;
  font-weight: lighter !important;
}

.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root {
  font-family: "SF Pro Display" !important;
  font-weight: lighter !important;
}

.button-combine {
  display: flex;
  justify-content: end;
}
.select-brand-bought {
  width: 100px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-radio-wrapper .ant-radio-checked::after{
  border: 1px solid #231f20 !important;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  background-color: #231f20 !important;
  border: 1px solid #231f20 !important;
}
.btn-save{
  border:1px solid #231f20 !important;
  width: 170px !important;
  background-color: #231f20 !important;
  color: #fff !important;
  height: 40px !important;
}
.vari{
  font-size: 14px;
  font-weight: 500;
}
.varihead{
  font-size: 18px;
  font-weight: 600;
}
.btnsave{
  background-color: #231F20 !important;
  width: 140px !important;
  height: 40px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.custom-spinner{
  background-color: #231f20 !important;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-input:placeholder-shown {
  margin-right: 15px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-upload-wrapper .ant-upload-drag {
  width: 100px;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-checkbox-checked .ant-checkbox-inner{
  background-color: #000 !important;
  border-color: #000 !important;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-pagination .ant-pagination-item-active{
  border-color: #000 !important;
}
.css-9gnw14.Mui-selected{
  color: #231F20 !important;
}
.css-314rep {
background-color: #231F20 !important;
}
.css-9gnw14{
  padding: 12px 6px !important;
}